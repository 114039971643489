@import '../../../../scss/theme-bootstrap';

.basic-menuref-renutriv {
  .page-navigation__menu & {
    .menu--lvl-2 {
      @media #{$xlarge-up} {
        &:before {
          background: $color-white;
        }
      }
      .menu--lvl-3 {
        .menu__item--category {
          &.menu__item--category-expandable {
            &:not(:first-child) {
              @media #{$xlarge-up} {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
    .menu__item--link {
      @media #{$xlarge-up} {
        margin-top: 10px;
      }
    }
  }
  .menu-ref__logo {
    display: none;
    position: absolute;
    z-index: 999;
    #{$ldirection}: 20px;
    top: 60px;
  }
  &.active-menu {
    .menu-ref__logo {
      @media #{$xlarge-up} {
        display: block;
      }
    }
  }
  .site-header-menu-formatter-v1 {
    @media #{$xlarge-up} {
      padding-top: 34px;
    }
  }
}
